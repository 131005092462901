*:last-child:not(.fmb) {
    margin-bottom: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

// Sticky footer
html,
body,
#root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

.app-header {
    flex: 0 0 auto;
}

.app-primary {
    flex: 1 0 auto;
}
// END Sticky footer

body {
    overflow-y: scroll;
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transition: opacity 0.5s;

    &.h {
        opacity: 0;
    }
}

.navbar-nav {
    &.branding {
        a:first-child {
            padding-left: 0;
        }
    }

    &.main-menu {
        a:last-child {
            padding-right: 0;
        }
    }
}

button,
.btn {
    &:disabled {
        border-color: #d0d0d0 !important;
        background-color: #d0d0d0 !important;
    }

    svg {
        margin-top: -0.2rem;
    }
}

.iconed {
    svg {
        margin-top: -0.1rem;
    }
}

.c-ptr {
    cursor: pointer;
}

.input-list-group {
    .list-group-item {
        padding: 0.75rem;
    }
}

.tight-gutters {
    margin-right: -3px;
    margin-left: -3px;

    > .col,
    > [class*='col-'] {
        padding-right: 3px;
        padding-left: 3px;
    }
}

.mb-frg {
    margin-bottom: $form-grid-gutter-width;
}
